import React from "react";
import { Link } from 'gatsby';
import * as Icon from 'react-feather';

import Logo from "../../assets/images/logo.png"

const Navbar = () => {
    const [menu, setMenu] = React.useState(true)

    const toggleNavbar = () => {
        setMenu(!menu)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("header");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <header id="header" className="headroom">
            <div className="startp-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link to="/" onClick={toggleNavbar} className="navbar-brand">
                            <img src={Logo} alt="konya yazılım firmaları" />
                        </Link>

                        {/* <button
                            onClick={toggleNavbar}
                            className={classTwo}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button> */}

                        <div className={classOne} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link to="/"  onClick={toggleNavbar} className="nav-link">
                                        Ana Sayfa
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" onClick={e => e.preventDefault()} className="nav-link">
                                        Şirketimiz <Icon.ChevronDown />
                                    </Link> 

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/pixel-yazilim-hakkinda"  onClick={toggleNavbar} className="nav-link">
                                                Hakkında
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/misyon-vizyon"  onClick={toggleNavbar} className="nav-link">
                                                Misyon & Vizyon
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/gizlilik-politikasi" onClick={toggleNavbar} className="nav-link">
                                               Gizlilik Politikası
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/konya-yazilim-firmasi-calisma-sekilleri"  onClick={toggleNavbar} className="nav-link">
                                               Çalışma Şekilleri
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link to="/konya-yazilim-firmalari-yeni-haberler" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                               Yeni Haberler
                                            </Link>
                                        </li> */}
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" onClick={e => e.preventDefault()} className="nav-link">
                                        Çözümler<Icon.ChevronDown />
                                    </Link> 

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/pera-pdks-yazilimlari-otomatik-vardiya-secim" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Pera PDKS Yazılımı
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/pro-steel-celik-konstruksiyon-uretim-takip-yazilimi" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                               Pro Steel - Çelik Konstruksiyon Üretim Takibi
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/bicak-izi-acilim-kutu-tasarimi" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                               Kutu Tasarımı - Ambalaj Çizimi
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/pawi-veteriner-asi-takip-yazilimi" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                               Pawi Net - Veteriner Yazılımı
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ozel-yazilim-firmasi" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                               Şirketinize Özel Uygulamalar
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/start-up-yazilim-ortakligi" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                               Parlak Fikirler Start-Up Projeleri Yazılım Ortaklığı 
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/mobil-uygulama-siparis-fatura" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                               Su, İçecek, Siparişleri Mobil Uygulamalar
                                            </Link>
                                        </li>
                                    
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="/yazilim-ekibi"  onClick={toggleNavbar} className="nav-link">
                                        Ekibimiz
                                    </Link>
                                </li>
                               
                                <li className="nav-item">
                                    <Link to="/referanslarimiz"  onClick={toggleNavbar} className="nav-link">
                                        Referanslar
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                        Home <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/it-startup" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                IT Startup
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/it-startup-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                IT Startup Two
                                            </Link>
                                        </li>
 
                                        <li className="nav-item">
                                            <Link to="/iot" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                IOT
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/hosting" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Hosting
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/machine-learning" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Machine Learning
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/machine-learning-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Machine Learning 2
                                            </Link>
                                        </li> 

                                        <li className="nav-item">
                                            <Link to="/bigdata-analytics" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Bigdata Analytics
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/digital-agency" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Digital Agency
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/digital-agency-portfolio" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Digital Agency Portfolio
                                            </Link>
                                        </li>
  
                                        <li className="nav-item">
                                            <Link to="/pc-repair" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                PC Repair
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" onClick={e => e.preventDefault()} className="nav-link">
                                        About <Icon.ChevronDown />
                                    </Link> 

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/about-1" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                About Style 1
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/about-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                About Style 2
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/about-3" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                About Style 3
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" onClick={e => e.preventDefault()} className="nav-link">
                                        Pages <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                Features <Icon.ChevronDown />
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/features" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Features
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/feature-details" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Features Details
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                Services <Icon.ChevronDown />
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/services-1" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Services Style 1
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/services-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Services Style 2
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/services-3" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Services Style 3
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/services-4" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Services Style 4
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/services-5" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Services Style 5
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/service-details" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Services Details
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/feedback" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Feedback
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                Projects <Icon.ChevronDown />
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/projects-1" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Project Style 1
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/projects-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Project Style 2
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/project-details" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Project Details
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/team" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Team
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/pricing" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Pricing
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                                User <Icon.ChevronDown />
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/login" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Login
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/sign-up" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Sign Up
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/forgot-password" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Forgot Password
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    
                                        <li className="nav-item">
                                            <Link to="/faq" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                FAQ's
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/coming-soon" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Coming Soon
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/404" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                404 Error Page
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                        Shop <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/shop" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Shop
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/product-details" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Products Details
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/cart" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Cart
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/checkout" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Checkout
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" onClick={e => e.preventDefault()} className="nav-link">
                                        Blog <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/blog-1" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Grid
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Right Sidebar
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-3" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Grid 2
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-4" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Right Sidebar 2
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-5" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Grid 3
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-6" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Right Sidebar 3
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-details" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Details
                                            </Link>
                                        </li>
                                    </ul>
                                </li> */}

                          
                            </ul>
                        </div>

                        <div className="others-option">
                            {/* <Link to="/" className="cart-wrapper-btn">
                                <Icon.ShoppingCart />
                                <span>0</span>
                            </Link> */}

                            <Link to="/contact" className="btn btn-light">
                                İLETİŞİM
                            </Link>

                            <Link to="/proje-talebi" className="btn btn-primary">
                                PROJE TALEBİ
                            </Link>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Navbar;